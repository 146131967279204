import React from 'react'
import Special from '../Menhome/Special'
import Coldcut from '../Menhome/Coldcut'
import Contact from '../Menhome/Contact'
import Banner from '../Menhome/Banner'

import { Link } from 'react-router-dom'
import sidebarlogo from '../../assets/images/resource/sidebar-logo.png'



function Main() {
    return (
        <>
            {/* <div>
                <img src={OfferImmage} alt="Nectar Hideaway" title="Nectar Hideaway" />
            </div> */}
            <div className="menu-backdrop"> </div>
            <section className="hidden-bar">
                <div className="inner-box">
                    <div className="cross-icon hidden-bar-closer"><span className="far fa-close"></span></div>
                    <div className="logo-box"><Link to="/" title="Nectar Hideaway"><img src={sidebarlogo} alt="Nectar Hideaway" title="Nectar Hideaway" /></Link></div>
                    {/* <li><Link to="/about">About Us</Link></li> */}

                </div>
            </section>

            <Banner />
            <Special />
            <Coldcut />
            <Contact />
        </>

    )
}

export default Main