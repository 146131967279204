import React from 'react'
import { Link } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

// import OfferImmage7 from '../../assets/images/resource/whispering.jpg'
import bookicon1 from '../../assets/images/resource/favicon.png'
// import OfferImmage7 from '../../assets/images/resource/offer7.jpg'
import OfferImmage7 from '../../assets/images/resource/whisperingBanner.jpg'


const scroll = () => {
    const section = document.querySelector('#menu-section');
    window.gtag('event', 'conversion', {'send_to': 'AW-11157048854/r9oBCImnxd8YEJacjMgp'});
    section.scrollIntoView({ behavior: 'smooth', block: 'start' });
};

function Banner() {
    return (
        <>

            <Swiper className="banner-section"
                loop={true}
                spaceBetween={50}
                slidesPerView={1}
                effect={'fade'}
                autoplay={{
                    delay: 5500,
                    disableOnInteraction: false,
                }}
                pagination={{
                    clickable: true,
                }}
                // navigation={true}
                modules={[Autoplay, Pagination, Navigation]}
                navigation={{
                    nextEl: '.swiper-button-prev',
                    prevEl: '.swiper-button-next',
                }}>

                <div style={{maxHeight: '50'}} className="banner-container">
                    <div className="banner-slider" >
                        <div className="swiper-wrapper">

                            <SwiperSlide>
                                <div className="swiper-slide slide-item">
                                    <div className="image-layer" style={{ backgroundImage: `url(${OfferImmage7})` }}></div>
                                    <div className="auto-container">
                                        <div className="content-box">
                                            <div className="content">
                                                <div className="clearfix">
                                                    <div className="inner">
                                                        <div className="subtitle"><span>delightful experience</span></div>
                                                        <div className="pattern-image">
                                                            <img src={require('../../assets/images/icons/separator.svg').default} alt='Nectar Hideaway Speakeasy Rooftop Cocktails Bar' />
                                                        </div>
                                                        <h2><span style={{ backdropFilter: 'blur(2px) saturate(70%)' }}>Where every flavor <br />tells a story</span></h2>
                                                        <div className="text" style={{ backdropFilter: 'blur(2px) saturate(70%)' }}>Unwind and Save: 30% Off Drinks Everyday! <br /> Join Our After Work & Happy Hours</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>

                         </div>

                    </div>
                </div>

                <div onClick={scroll} className="book-btn">
                    <Link to="#" className="theme-btn plausible-event-name=Menu-top-click">
                        <span className="icon">
                            <img src={bookicon1} alt="Nectar Hideaway" title="" />
                        </span>
                        <span className="txt">Menu</span>
                    </Link>
                </div>

            </Swiper>

        </>
    )
}

export default Banner