import React from 'react'
import { Link } from 'react-router-dom'
import OwlCarousel from 'react-owl-carousel2';
import logonew from '../../assets/images/resource/favicon.png'

import silenceGolden from '../../assets/images/resource/silenceGolden.jpg'
import lessential from '../../assets/images/resource/lessential.jpg'
import starryNight from '../../assets/images/resource/starryNight.jpg'
import nectarDevie from '../../assets/images/resource/garden.jpg'
import garden from '../../assets/images/resource/nectarDeVie.jpg'
import broken2 from '../../assets/images/resource/brokenheart.jpg'
import whispering from '../../assets/images/resource/whispering.jpg'
import matcha from '../../assets/images/resource/matcha.jpg'
import lullaby from '../../assets/images/resource/lullaby.jpg'
import nothing from '../../assets/images/resource/nothing.jpg'

const Wine = [
    {
        name: 'Nectar de Vie',
        price: '229.000',
        base: 'Tequila || Sweet, bitter, refreshing, aromatic, with a hint of spice.',
        image: nectarDevie,

        description:  'Coffee, Sweet basil, Orange bitter, Kaffir Lime Cordial',
    },
    {
        name: 'Silence is golden',
        price: '229.000',
        image: silenceGolden,
        description:  'Thai matcha, Wasabi, Orange flower water',
    },

    {
        name: 'Whispering of the woods',
        price: '229.000',
        image: whispering,
        description:  'Sandalwood, Osmanthus, Earlgrey, Cab Sauv, Lime Cordial',
    },
    {
        name: "L'essential",
        price: '229.000',
        image: lessential,
        description:  'Jasmine cordial, Cardamom, Fennel, Sour apple',
    },
    {
        name: 'Starry Night',
        price: '229.000',
        image: starryNight,
        description:  'Basil, Thyme, Oregano, Creme de Violet, Lemon bitter, Lime cordial',
    },
    {
        name: 'Lullaby Martini',
        price: '229.000',
        base: 'Gin',
        image: lullaby,
        description:  'Chamomile, Licorice, Goji berries',
    },
    {
        name: 'Boulevard Of Broken Dreams',
        price: '229.000',
        image: broken2,
        description:  'Ylang Ylang Tea,  Lemon & Rasperry Cordial',
    },
    {
        name: 'Garden of words',
        price: '229.000',
        image: garden,
        description:  'Hibiscus, Osmanthus, Coconut Liquor, Longan Cordial, Florals Bun',
    },
    {
        name: 'Nothing to say',
        price: '179.000',
        image: nothing,
        description: 'Vegan foam, Raspberry, beetroot, lime',
    },
    {
        name: "Swipe to match'a",
        price: '179.000',
        image: matcha,
        description: 'Vegan Foam, Rich Cream, Matcha, Osmanthus, Orangeflower Water, Lime, Soda',
    }
]


function Special() {
    const options = {
        items: 4,
        rewind: true,
        loop: true,
        margin: 45,
        nav: true,
        smartSpeed: 700,
        autoplay: true,
        autoplayTimeout: 7000,
        navText: ['<span class="icon fa-light fa-angle-left"></span>', '<span class="icon fa-light fa-angle-right"></span>'],
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 1
            },
            768: {
                items: 2,
                margin: 30
            },
            992: {
                items: 3,
                margin: 30
            },
            1200: {
                items: 4
            }
        }
    };

    const filterdMenu = Wine;

    return (
        <>
            <section id="menu-section" className="special-offer" style={{marginBottom: 0, paddingBottom: 0}}>
                <div className="outer-container" style={{paddingBottom: 0}}>
                    <div className="auto-container plausible-event-name=Signatures-click">
                        <div className="title-box centered">
                            <div className="pattern-image">
                                <img src={require('../../assets/images/icons/separator.svg').default} alt='Nectar Hideaway Speakeasy Rooftop Cocktails Bar, Signature, Cocktail, Mocktails' />
                            </div>
                            <h1> Signatures </h1>
                        </div>
                        <OwlCarousel className="dish-gallery-slider owl-carousel owl-theme" options={options}>
                            {
                                Wine.map(it => {
                                    return (
                                        <div className="offer-block-two margin-top">
                                            <div className="iner-box">
                                                <div className="image">
                                                    <div>
                                                        <img src={it.image || logonew} alt="Nectar Hideaway" />
                                                    </div>
                                                </div>
                                                <h4>
                                                    <div style={{ display: 'flex', textAlign: 'left', justifyContent: 'space-between' }}>
                                                        <div>{it.name}</div>
                                                        <div className="price">{it.price}</div>
                                                    </div>
                                                </h4>
                                                <div className="text desc" style={{ display: 'flex', textAlign: 'left' }}>{it.description}</div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </OwlCarousel>
                    </div>
                    <div className="tabs-box menu-tabs" style={{ marginTop: 20 }}>
                        <div className="tabs-content">
                            <div className={`tab active-tab`} id="tab-1">
                                <div className="row clearfix">
                                    <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                                        <div className="inner">
                                            {filterdMenu.map((item, index) => {
                                                if (index % 2 !== 0) return null;
                                                return (
                                                    <div className="dish-block" style={{paddingLeft: 15, marginBottom: 20}}>
                                                        <div className="inner-box"style={{paddingLeft: 0}}>
                                                            {/* <div className="dish-image"><Link to="#"><img src={item.image || logonew} alt={`Nectar Hideaway Speakeasy Rooftop Cocktails Bar, ${item.name}`} /></Link></div> */}
                                                            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                                                <div className="ttl clearfix">
                                                                    <h5 style={{ backgroundColor: '#0e0d0c', color: 'white' }}>{item.name}</h5>
                                                                </div>
                                                                <div className="price"><span style={{color: 'rgb(228,197,144)'}}>{item.price}</span></div>
                                                            </div>
                                                            <div className="text desc"><Link to="#">{item.description}</Link></div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            }
                                        </div>
                                    </div>
                                    <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                                        <div className="inner">
                                            {filterdMenu.map((item, index) => {
                                                if (index % 2 === 0) return null;
                                                return (
                                                    <div className="dish-block" style={{paddingLeft: 15, marginBottom: 20}}>
                                                        <div className="inner-box"style={{paddingLeft: 0}}>
                                                            {/* <div className="dish-image"><Link to="#"><img src={item.image || logonew} alt={`Nectar Hideaway Speakeasy Rooftop Cocktails Bar, ${item.name}`} /></Link></div> */}
                                                            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                                                <div className="ttl clearfix">
                                                                    <h5 style={{ backgroundColor: '#0e0d0c', color: 'white' }}>{item.name}</h5>
                                                                </div>
                                                                <div className="price"><span style={{color: 'rgb(228,197,144)'}}>{item.price}</span></div>
                                                            </div>
                                                            <div className="text desc"><Link to="#">{item.description}</Link></div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>

    )
}

export default Special