import React, { useState } from 'react'
import { useNavigate } from "react-router-dom";

import { Link } from 'react-router-dom'
import Bgtwo from '../../assets/images/background/contact-background.jpg'
import * as dayjs from 'dayjs'

import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(customParseFormat);

const key = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImhicWFodHZsdXRlZ2JpbnB2Znl2Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTE5NDc5MTksImV4cCI6MjAyNzUyMzkxOX0.XgGUChiCUYC_F-sBoT7NRweeZjVD16DA8DMwjVlt__k';

function Contact() {
    const [date, setDate] = useState(dayjs().format('dddd, DD-MM-YYYY'));
    const [isBooking, setIsBooking] = useState(false);
    const [time, setTime] = useState('05 : 30 pm');
    const [person, setPerson] = useState('1 Person');
    const [name, setName] = useState(null);
    const [phone, setPhone] = useState(null);
    const [message, setMessage] = useState('nothing');
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();

    const createRow = () => {
        const payload =  {
            'name': name,
            "guests": person ? person.split(' ')[0] : 1,
            "date": dayjs(date, 'dddd, DD-MM-YYYY').format('YYYY-MM-DD'),
            "isCancelled": false,
            "time": time,
            "contact":phone,
            "note":message,
            "source": window.origin
        }

        const url = 'https://team.nectarhideaway.com/api/booking'
        return fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        }).catch(() => {
            console.log('error')
        })
    }


    const handleSubmit = (e) => {
        e.preventDefault();
        navigate('/success')
        const url = 'https://script.google.com/macros/s/AKfycbygYzxNngPyhjTXG0TdK0yDU_LRHF_iEr0py4LhBqGh50ajhs7LuOmrfodbu53CYRE/exec';
        const formData = new FormData();
        
        formData.append('name', name);
        formData.append('phone', "'" + phone);
        formData.append('date', date);
        formData.append('time', time);
        formData.append('person', person);
        formData.append('booking', message);
        setIsLoading(true);


        fetch(url, {
            method: 'POST',
            body: formData,
        })
        .then(() => {
            setIsBooking(true);
            window.gtag('event', 'conversion', {'send_to': 'AW-11157048854/r9oBCImnxd8YEJacjMgp'});
        })
        createRow();
    }

    return (
        <>
            <div className="testimonials-section" >
                <div className="image-layer" style={{ backgroundImage: `url(${Bgtwo})` }}></div>
                <div className="auto-container">
                    <div className="carousel-box owl-theme">
                        <div className="separator"><span></span><span></span><span></span></div>
                    </div>
                </div>
            </div>

            <section className="reserve-section">
                <div className="auto-container">
                    <div className="outer-box">

                        <div className="row clearfix">
                            <div className="reserv-col col-lg-8 col-md-12 col-sm-12">
                                <div className="inner">
                                    <div className="title">
                                        <h1>Online Reservation</h1>
                                        <div className="request-info">Booking request <Link to="#">+84869452656</Link></div>
                                        <div className="request-info">or click Whatsapp &nbsp;
                                            <a target="_blank" href="https://api.whatsapp.com/send?phone=84988360294&text=Hello,%20I%20would%20like%20to%20book%20a%20table">
                                                Whatsapp</a>
                                        </div>
                                        <div>or fill out the order form</div>
                                    </div>
                                    {!isBooking && (
                                        <div className="default-form reservation-form">
                                            <form onSubmit={handleSubmit}>
                                                <div className="row clearfix">
                                                    <div className="form-group col-lg-6 col-md-6 col-sm-12">
                                                        <div className="field-inner">
                                                            <input onChange={e => setName(e.target.value)} type="text" name="name" placeholder="Your Name" required />
                                                        </div>
                                                    </div>
                                                    <div className="form-group col-lg-6 col-md-6 col-sm-12">
                                                        <div className="field-inner">
                                                            <input onChange={e => setPhone(e.target.value)} type="text" name="phone" placeholder="Phone Number / Email" required />
                                                        </div>
                                                    </div>
                                                    <div className="form-group col-lg-4 col-md-6 col-sm-12">
                                                        <div className="field-inner">
                                                            <span className="alt-icon far fa-user"></span>
                                                            <select onChange={e => setPerson(e.target.value)} className="l-icon">
                                                                <option>1 Person</option>
                                                                <option>2 People</option>
                                                                <option>3 People</option>
                                                                <option>4 People</option>
                                                                <option>5 People</option>
                                                                <option>6 People</option>
                                                                <option>7 People</option>
                                                            </select>
                                                            <span className="arrow-icon far fa-angle-down"></span>
                                                        </div>
                                                    </div>
                                                    <div className="form-group col-lg-5 col-md-6 col-sm-12">
                                                        <div className="field-inner">
                                                            <span className="alt-icon far fa-calendar"></span>
                                                            <select onChange={e => setDate(e.target.value)} className="l-icon">
                                                                <option>{dayjs().format('dddd, DD-MM-YYYY')}</option>
                                                                <option>{dayjs().add(1, 'd').format('dddd, DD-MM-YYYY')}</option>
                                                                <option>{dayjs().add(2, 'd').format('dddd, DD-MM-YYYY')}</option>
                                                                <option>{dayjs().add(3, 'd').format('dddd, DD-MM-YYYY')}</option>
                                                                <option>{dayjs().add(4, 'd').format('dddd, DD-MM-YYYY')}</option>
                                                                <option>{dayjs().add(5, 'd').format('dddd, DD-MM-YYYY')}</option>
                                                                <option>{dayjs().add(6, 'd').format('dddd, DD-MM-YYYY')}</option>
                                                                <option>{dayjs().add(7, 'd').format('dddd, DD-MM-YYYY')}</option>
                                                            </select>
                                                            <span className="arrow-icon far fa-angle-down"></span>
                                                        </div>
                                                    </div>
                                                    <div className="form-group col-lg-3 col-md-12 col-sm-12">
                                                        <div className="field-inner">
                                                            <span className="alt-icon far fa-clock"></span>
                                                            <select onChange={e => setTime(e.target.value)} className="l-icon">
                                                                <option>05 : 30 pm</option>
                                                                <option>06 : 00 pm</option>
                                                                <option>07 : 00 pm</option>
                                                                <option>08 : 00 pm</option>
                                                                <option>09 : 00 pm</option>
                                                                <option>10 : 00 pm</option>
                                                                <option>11 : 00 pm</option>
                                                            </select>
                                                            <span className="arrow-icon far fa-angle-down"></span>
                                                        </div>
                                                    </div>
                                                    <div className="form-group col-lg-12 col-md-12 col-sm-12">
                                                        <div className="field-inner">
                                                            <textarea 
                                                                name="message" 
                                                                onChange={e => setMessage(e.target.value)}
                                                                placeholder="Message(Any other questions or Special requests)" 
                                                            ></textarea>
                                                        </div>
                                                    </div>
                                                    <div className="form-group col-lg-12 col-md-12 col-sm-12">
                                                        <div className="field-inner">

                                                            <button 
                                                                disabled={isLoading}
                                                                type="submit" 
                                                                className="theme-btn plausible-event-name=Booking+Click btn-style-one clearfix"
                                                            >
                                                                <span className="btn-wrap">
                                                                    <span className="text-one">book a table</span>
                                                                    <span className="text-two">book a table</span>
                                                                </span>
                                                            </button>

                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    )

                                    }
                                    {isBooking && (
                                        <div class="info">
                                            <div style={{ textAlign: 'center' }}><strong>Thank you for booking with us!</strong></div>
                                            We have received your reservation request and will be in touch shortly to confirm. <br />
                                            Get ready for an amazing experience with us. <br />
                                            <div style={{ textAlign: 'center' }}>See you soon!</div>
                                        </div>
                                    )

                                    }
                                </div>
                            </div>
                            <div className="info-col col-lg-4 col-md-12 col-sm-12">
                                <div className="inner">
                                    <div className="title">
                                        <h2>Contact Us</h2>
                                    </div>
                                    <div className="data">
                                        <div className="booking-info">
                                            <div className="bk-title">Booking request</div>
                                            <div className="bk-no"><Link to="tel:+84869452656">+84 869452656</Link></div>
                                        </div>
                                        <div className="separator"><span></span></div>
                                        <ul className="info">
                                            <li><strong>Location</strong><br />4th Floor, 20 Trang Tien, Hoan Kiem, Ha Noi </li>
                                            <li><strong>Operating Hours</strong><br />Monday to Sunday <br />05.00 pm - 00.30am</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default Contact