import React from 'react'
import { Link } from 'react-router-dom'
import OwlCarousel from 'react-owl-carousel2';
import logonew from '../../assets/images/resource/favicon.png'

import silenceGolden from '../../assets/images/resource/silenceGolden.jpg'
import lessential from '../../assets/images/resource/lessential.jpg'
import starryNight from '../../assets/images/resource/starryNight.jpg'
import nectarDevie from '../../assets/images/resource/garden.jpg'
import garden from '../../assets/images/resource/nectarDeVie.jpg'
import broken2 from '../../assets/images/resource/brokenheart.jpg'
import whispering from '../../assets/images/resource/whispering.jpg'
import matcha from '../../assets/images/resource/matcha.jpg'
import lullaby from '../../assets/images/resource/lullaby.jpg'
import nothing from '../../assets/images/resource/nothing.jpg'
import cold from '../../assets/images/resource/1000015991-removebg.png'
import cold2 from '../../assets/images/resource/1000015962-removebg.png'
import cold3 from '../../assets/images/resource/1000015956-removebg.png'
import cold4 from '../../assets/images/resource/1000015954-removebg_out.png'

// import silenceGolden from 'https://blogs.inter.ducphuclee.tech/wp-content/uploads/2023/08/starryNight-scaled.jpg'
// import lessential from 'https://blogs.inter.ducphuclee.tech/wp-content/uploads/2023/08/lessential-scaled.jpg'
// import starryNight from 'https://blogs.inter.ducphuclee.tech/wp-content/uploads/2023/08/starryNight-scaled.jpg'
// import nectarDevie from 'https://blogs.inter.ducphuclee.tech/wp-content/uploads/2023/08/nectarDeVie-scaled.jpg'
// import broken2 from 'https://blogs.inter.ducphuclee.tech/wp-content/uploads/2023/08/brokenheart-1-scaled.jpg'
// import whispering from 'https://blogs.inter.ducphuclee.tech/wp-content/uploads/2023/08/whispering-scaled.jpg'
// import garden from 'https://blogs.inter.ducphuclee.tech/wp-content/uploads/2023/08/garden-scaled.jpg'
// import matcha from 'https://blogs.inter.ducphuclee.tech/wp-content/uploads/2023/08/matcha-scaled.jpg'
// import lullaby from 'https://blogs.inter.ducphuclee.tech/wp-content/uploads/2023/08/lullaby-scaled.jpg';
// import nothing from 'https://blogs.inter.ducphuclee.tech/wp-content/uploads/2023/08/nothing-scaled.jpg'





// import bgone from '../../assets/images/background/bg-1.png'
// import five from '../../assets/images/resource/wine.png'
// import Bgtwo from '../../assets/images/background/bg-2.png'

const Wine = [
    {
        name: 'Tapas',
        price: '100.000 / 150.000',
        // base: 'cold',
        image: cold,

        description:  'Tapas: 3 - 5 pcs',
    },
    {
        name: 'Charcuterie board',
        price: '250.000 / 300.000',
        // base: 'cold',
        image: cold2,

        // description:  'cuts',
    },
    {
        name: 'Pickles Mix',
        price: '60.000',
        // base: 'cold',
        image: cold3,

        description:  'Olive - Pickles - Tomato',
    },
    {
        name: 'Chips',
        price: '60.000',
        // base: 'cold',
        image: cold4,
        // description:  'cuts',
    },
]


function Special() {
    const options = {
        items: 4,
        rewind: true,
        loop: true,
        margin: 45,
        nav: true,
        smartSpeed: 700,
        autoplay: true,
        autoplayTimeout: 7000,
        navText: ['<span class="icon fa-light fa-angle-left"></span>', '<span class="icon fa-light fa-angle-right"></span>'],
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 1
            },
            768: {
                items: 2,
                margin: 30
            },
            992: {
                items: 3,
                margin: 30
            },
            1200: {
                items: 4
            }
        }
    };

    return (
        <>
            <section id="menu-section" className="special-offer" style={{marginBottom: 0, paddingBottom: 0}}>
                <div className="outer-container" style={{paddingBottom: 0}}>
                    <div className="auto-container plausible-event-name=Signatures-click">
                        <div className="title-box centered">
                            <div className="pattern-image">
                                <img src={require('../../assets/images/icons/separator.svg').default} alt='Nectar Hideaway Speakeasy Rooftop Cocktails Bar, Signature, Cocktail, Mocktails' />
                            </div>
                            <h1> Foods </h1>
                        </div>
                        <OwlCarousel className="dish-gallery-slider owl-carousel owl-theme" options={options}>
                            {
                                Wine.map(it => {
                                    return (
                                        <div className="offer-block-two margin-top">
                                            <div className="iner-box">
                                                <div 
                                                    // style={{background: 'black'}}
                                                >
                                                    <div>
                                                        <img src={it.image || logonew} alt="Nectar Hideaway Speakeasy Rooftop Cocktails Bar, Siganture" />
                                                    </div>
                                                </div>
                                                <h4>
                                                    <div style={{ display: 'flex', textAlign: 'left', justifyContent: 'space-between' }}>
                                                        <div>{it.name}</div>
                                                        <div className="price">{it.price}</div>
                                                    </div>
                                                </h4>
                                                {/* <div className="text desc" style={{ display: 'flex' }}>{it.base}</div> */}
                                                <div className="text desc" style={{ display: 'flex', textAlign: 'left' }}>{it.description}</div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </OwlCarousel>
                    </div>

                    <div className="tabs-box menu-tabs" style={{ marginTop: 20 }}>
                        <div className="tabs-content">
                            <div className={`tab active-tab`} id="tab-1">
                                <div className="row clearfix">
                                    <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                                        <div className="inner">
                                            {Wine.map((item, index) => {
                                                if (index % 2 !== 0) return null;
                                                return (
                                                    <div className="dish-block" style={{paddingLeft: 15, marginBottom: 20}}>
                                                        <div className="inner-box"style={{paddingLeft: 0}}>
                                                            {/* <div className="dish-image"><Link to="#"><img src={item.image || logonew} alt={`Nectar Hideaway Speakeasy Rooftop Cocktails Bar, ${item.name}`} /></Link></div> */}
                                                            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                                                <div className="ttl clearfix">
                                                                    <h5 style={{ backgroundColor: '#0e0d0c', color: 'white' }}>{item.name}</h5>
                                                                </div>
                                                                <div className="price"><span style={{color: 'rgb(228,197,144)'}}>{item.price}</span></div>
                                                            </div>
                                                            <div className="text desc"><Link to="#"><i>{item.base}</i></Link></div>
                                                            <div className="text desc"><Link to="#">{item.description}</Link></div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            }
                                        </div>
                                    </div>
                                    <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                                        <div className="inner">
                                            {Wine.map((item, index) => {
                                                if (index % 2 === 0) return null;
                                                return (
                                                    <div className="dish-block" style={{paddingLeft: 15, marginBottom: 20}}>
                                                        <div className="inner-box"style={{paddingLeft: 0}}>
                                                            {/* <div className="dish-image"><Link to="#"><img src={item.image || logonew} alt={`Nectar Hideaway Speakeasy Rooftop Cocktails Bar, ${item.name}`} /></Link></div> */}
                                                            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                                                <div className="ttl clearfix">
                                                                    <h5 style={{ backgroundColor: '#0e0d0c', color: 'white' }}>{item.name}</h5>
                                                                </div>
                                                                <div className="price"><span style={{color: 'rgb(228,197,144)'}}>{item.price}</span></div>
                                                            </div>
                                                            <div className="text desc"><Link to="#"><i>{item.base}</i></Link></div>
                                                            <div className="text desc"><Link to="#">{item.description}</Link></div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <>

                    {/* <div className="left-bg"><img src={bgone} alt="Nectar Hideaway Speakeasy Rooftop Cocktails Bar" title="" /></div> */}
                    <div style={{ marginTop: 30 }}></div>

                    {/* <div className="right-bg"><img src={Bgtwo} alt="Nectar Hideaway Speakeasy Rooftop Cocktails Bar" title="" /></div> */}

                    <div className="auto-container">


                    </div>

                </>
            </section>


        </>

    )
}

export default Special